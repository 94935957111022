import {
  ArrowRightIcon,
  BugIcon,
  ChevronUpIcon,
  CommentDiscussionIcon,
  MarkGithubIcon,
  MegaphoneIcon,
} from "@primer/octicons-react";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Advert, Footnote, NavItem } from "..";
import chromedevs from "../../assets/images/chrome-devs.png";
import { MainContext } from "../../contexts";
import Docs from "../../data/docs.json";
import "./sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const { showMenu } = useContext(MainContext);
  const [showAPIs, setShowAPIs] = useState(true);
  const [data, setData] = useState<
    {
      title: string;
      path: string;
    }[]
  >([]);

  useEffect(() => {
    if (location && location.pathname === "/get-started") setShowAPIs(false);
  }, [location]);

  useEffect(() => {
    const formatted = JSON.parse(JSON.stringify(Docs));
    const sorted = formatted.sort(
      (a: { title: string }, b: { title: string }) =>
        a.title.localeCompare(b.title)
    );
    setData(sorted);
  }, []);

  const ItemList = data.map((doc) => <NavItem key={doc.path} item={doc} />);

  return showMenu ? (
    <section className="docs-flex-left">
      <section className="sidebar-inner">
        <Link to="/get-started" className="sidebar-nav-item">
          <span>Introduction</span>
        </Link>
        <section>
          <div
            className="sidebar-title"
            onClick={(event) => {
              event.preventDefault();
              setShowAPIs((prev) => !prev);
            }}>
            <span className="sidebar-title-left">All APIs ({data.length})</span>{" "}
            <span className="sidebar-title-right">
              {showAPIs ? (
                <ChevronUpIcon size={24} />
              ) : (
                <ArrowRightIcon size={24} />
              )}
            </span>
          </div>
          <section
            className="sidebar-nav-item-container"
            style={showAPIs ? {} : { display: "none" }}>
            {ItemList}
          </section>
        </section>
        <a
          href="https://github.com/pwafire/pwafire/issues/new/choose"
          className="sidebar-nav-item"
          target="_blank"
          rel="noreferrer">
          <BugIcon /> <span className="sidebar-nav-item-text ">Bug</span>
        </a>
        <a
          href="https://github.com/pwafire/pwafire"
          className="sidebar-nav-item"
          target="_blank"
          rel="noreferrer">
          <MarkGithubIcon />{" "}
          <span className="sidebar-nav-item-text ">Contribute</span>
        </a>
        <a
          href="https://github.com/pwafire/pwafire/discussions/categories/q-a"
          className="sidebar-nav-item"
          target="_blank"
          rel="noreferrer">
          <CommentDiscussionIcon />{" "}
          <span className="sidebar-nav-item-text">Community</span>
        </a>
        <section className="sidebar-info">
          <Advert className="advert">
            <div className="sidebar-info-title">
              {" "}
              <MegaphoneIcon size={16} />
              <span style={{ marginLeft: 5 }}>New Sponsor 💕</span>
            </div>
            <div>
              <b>Google Chrome</b> through the{" "}
              <a
                href="https://developer.chrome.com/blog/advanced-web-apps-fund/"
                target="_blank"
                rel="noreferrer">
                Advanced Web Apps Fund
              </a>{" "}
              is supporting our work to make the Web better for everyone.
            </div>
            <img src={chromedevs} alt="Wait for me :)" loading="lazy" />
          </Advert>
          <div className="sidebar-info-title">What are PWAFire APIs?</div>
          <div className="sidebar-info-text">
            <p>
              Built on top of{" "}
              <a
                href="https://www.chromium.org/teams/web-capabilities-fugu/"
                target="_blank"
                rel="noreferrer">
                Project Fugu
              </a>
              , an effort to close gaps in the web's capabilities and native
              mobile and desktop apps.
            </p>
            <p
              className="quote"
              style={{
                padding: 15,
                fontSize: 13,
              }}>
              {" "}
              PWAFire APIs are modern web apis bundled together into a single
              package or api aka <b>pwafire</b>
            </p>
          </div>
          <div className="sidebar-info-title">
            What are Progressive Web Apps(PWAs)?
          </div>
          <div className="sidebar-info-text">
            <p>
              Progressive Web Apps are web apps that are built with the latest
              web technologies and are designed to be installed on mobile
              devices and desktops.
            </p>
          </div>
          <div className="sidebar-info-title">You might be interested in</div>
          <div className="sidebar-info-text">
            <div
              className="quote"
              style={{
                padding: 15,
                fontSize: 13,
              }}>
              <ul>
                <li>
                  <a
                    href="https://read.pwafire.org/"
                    target="_blank"
                    rel="noreferrer">
                    Read Blog
                  </a>{" "}
                  by pwafire.org. A blog dedicated to PWAs and modern web
                  technologies.
                </li>
                <li>
                  <a
                    href="https://marketplace.visualstudio.com/items?itemName=mayeedwin.vscode-pwa"
                    target="_blank"
                    rel="noreferrer">
                    Visual Studio Code Extension
                  </a>{" "}
                  for PWAs.
                </li>
              </ul>
            </div>
          </div>
          <Footnote />
        </section>
      </section>
    </section>
  ) : null;
};

export default Sidebar;
