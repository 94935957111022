import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Visibility = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Visibility API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/visibility",
                title: "Visibility API",
                text: "Do something while a page is being viewed",
              }}
            />
          </div>
          <h3>​Do something while a page is being viewed</h3>
          <p className="quote">
            Check if user is viewing a page. Pause/play video or games e.t.c
          </p>
          <p>
            <DotIcon />
            Define a page visibilty handler
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Do something....
  const isVisible = () => {
    //...
  };`}</code>
          </pre>
          <p>
            <DotIcon />
            Call the <b>Visibility</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{` pwa.Visibility(isVisible);`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Visibility;
