import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const ContentIndexing = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Content Indexing API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Jan 31 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/content-indexing",
                title: "Content Indexing API",
                text: "Index your offline-capable pages with the Content Indexing API",
              }}
            />
          </div>
          <h3>Index your offline-capable pages</h3>
          <p className="quote">
            Allows developers to add URLs and metadata of offline-capable pages
            to a local index maintained by the browser. Launched in Chrome 84
            for Android. Learn more{" "}
            <a
              href="https://web.dev/content-indexing-api/"
              target="_blank"
              rel="noreferrer">
              here.
            </a>
          </p>
          <p>
            <DotIcon />
            Call the <b>contentIndexing</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const index = await pwa.contentIndexing();
  if (index.ok) {
    // Do something...like
    // 1. Add a page to the index...
    // 2. Remove a page from the index...
    // 3. Get all indexed pages...
  }`}</code>
          </pre>
          <p>
            <DotIcon />
            Add a page to the index
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const res = await index.addItem({
    // Required; set to something unique within your web app.
    id: "article-123",
    // Required; url needs to be an offline-capable HTML page.
    url: "/articles/123",
    // Required; used in user-visible lists of content.
    title: "Article title",
    // Required; used in user-visible lists of content.
    description: "Amazing article about things!",
    // Required; used in user-visible lists of content.
    icons: [
      {
        src: "/img/article-123.png",
        sizes: "64x64",
        type: "image/png",
      },
    ],
    // Optional; valid categories are currently:
    // 'homepage', 'article', 'video', 'audio', or '' (default).
    category: "article",
  });`}</code>
          </pre>
          <p>
            <DotIcon />
            Remove a page from the index
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const res = index.removeItem({
    // Required; provide the id of the item to remove...
    id: "article-123",
  });`}</code>
          </pre>
          <p>
            <DotIcon />
            Get all indexed pages
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const items = await index.getAll();`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default ContentIndexing;
