const Footnote = () => {
  return (
    <div className="doc-footer">
      {" "}
      Copyright{" "}
      <a href="https://maye.pwafire.org" target="_blank" rel="noreferrer">
        Maye Edwin
      </a>{" "}
      {new Date().getFullYear()} . All rights reserved.
    </div>
  );
};

export default Footnote;
