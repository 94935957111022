import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Install = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Install PWA API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: May 30 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/custom-install-prompt",
                title: "Add custom in-app install experience",
                text: "​Customizing the install prompt for your progressive web app",
              }}
            />
          </div>
          <h3>Add custom in-app install experience</h3>
          <p className="quote">
            Provide a step <b>type</b> which can be <i>before</i>,{" "}
            <i>install</i> or <i>installed</i> and a <b>callback function</b>{" "}
            which will be called when an API step is triggered
          </p>
          <p>
            <DotIcon />
            Call the <b>Install</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Using the install api...
  pwa.Install(step, (event) => {
    // Do something with the event...
  });`}</code>
          </pre>
          <h3>The installation steps</h3>
          <p className="quote">
            Make sure <b>before</b> and <b>installed</b> types run on{" "}
            <b>page load</b>, and <b>install</b> step is called inside a button
            click event. In a React application, for e.g, the first two steps
            should be in a <b>useEffect</b> hook
          </p>
          <p>
            <DotIcon />
            <b>Before</b> step:{" "}
            <i>Runs on page load and checks if app can be installed</i>
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // 1. Before install prompt is shown.
pwa.Install("before", (event) => {
  // Prevent the mini-infobar from appearing e.g for mobile.
  if (window.matchMedia("(min-width: 767px)").matches) {
    event.preventDefault();
  }
  // a) => Stash the event so it can be triggered later on.
  setSavedDefferedPrompt(event);
  // b) => Update UI notify the user they can install the PWA.
  setIsInstalled(false);
  // c) => Optionally, send analytics event that PWA install promo was shown.
  //...
});`}</code>
          </pre>
          <p>
            <DotIcon />
            <b>Install</b> step:{" "}
            <i>Runs when the user clicks the install button</i>
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // 2. Show the install prompt.
  pwa.Install("install", async (event: string) => {
    // Event type is install.
    console.log(event);
    // a) => Show the install prompt.
    savedDefferedPrompt.prompt();
    // b) =>  Wait for the user to respond to the prompt.
    const { outcome } = await savedDefferedPrompt.userChoice;
    if (outcome === "accepted") {
      // c, i) =>  Optionally, send analytics event with outcome of user choice.
    } else {
      // c, ii) => Optionally, send analytics event with outcome of user choice.
    }
    // d) => We've used the prompt, and can't use it again, throw it away.
    setSavedDefferedPrompt(null);
});`}</code>
          </pre>
          <p>
            <DotIcon />
            <b>Installed</b> step:{" "}
            <i>Runs when the app is installed, and on page load</i>
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // 3. Check if app was installed...
  pwa.Install("installed", () => {
    // b) => Hide the app-provided install promotion custom button.
    setIsInstalled(true);
    // c) => Clear the deferredPrompt so it can be garbage collected.
    setSavedDefferedPrompt(null);
    // d) => Optionally, send analytics event to indicate successful install.
    //...
});`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Install;
