import styled from "styled-components";

const StyledAdvert = styled.div`
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
`;
const Advert = ({
    message = "This is a default message",
    children = null,
    className = "",
}: {
    message?: string;
    children?: React.ReactNode;
    className?: string;
}) => {
    return children ? <StyledAdvert className={className}>{children}</StyledAdvert> : <div className="advert">
        <div>{message}</div>
    </div>;
};

export default Advert;
