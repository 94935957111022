import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const IdleDetection = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Idle Detection API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Dec 20 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/idle-detection",
                title: "Idle Detection API",
                text: "Notify the user of new activity",
              }}
            />
          </div>
          <h3>​Detect inactive users or user activity</h3>
          <p className="quote">
            The <b>Idle Detection API</b> helps developers to find out when the
            user isn't actively using their device.
          </p>
          <p>
            <DotIcon />
            Start the detector
          </p>
          <p className="quote">
            Provide an <b>action</b> i.e "start" and a <b>callback function</b>{" "}
            that will be called when the user is inactive(required). You can
            optionally provide a <b>"threshold"</b> in milliseconds(minimum
            threshold is 60000ms). By default <b>action</b> is set to "start"
            and <b>threshold</b> is set to 60000ms.
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Setup parameters
  const action = "start";
  const callback = () => {
    // Do something...
  }
  // Start the detector
  pwa.idleDetection(action, callback);`}</code>
          </pre>
          <p>
            <DotIcon />
            Abort the idle detection
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Abort the idle detection
  pwa.idleDetection("stop")`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default IdleDetection;
