import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const WebPayment = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Web Payment API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Jan 27 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/web-payment",
                title: "Web Payment API",
                text: "Allows users select their preferred way of paying for things, and make that information available to a merchant",
              }}
            />
          </div>
          <h3>Pay via the web</h3>
          <p className="quote">
            Allows users select their preferred way of paying for things, and
            make that information available to a merchant. Have a look at the{" "}
            <a
              href="https://webpay.glitch.me/"
              target="_blank"
              rel="noreferrer">
              example here
            </a>
            . This API works in Chrome and MS Edge only.
          </p>
          <p>
            <DotIcon />
            Compute total amount to pay
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Calculations...
  const payment = {
    price: 10,
    get discount() {
      return this.price * 0.005;
    },
    get tax() {
      return 0.14 * this.price;
    },
    get total() {
      return (this.price + this.tax) - this.discount;
    },
  ;

  // Destructure payment object...
  const { tax, discount, total } = payment;`}</code>
          </pre>

          <p>
            <DotIcon />
            Set Payment methods. Check for more payload values{" "}
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/PaymentRequest/PaymentRequest"
              target="_blank"
              rel="noreferrer">
              here
            </a>
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const paymentMethods = [
  {
      supportedMethods: ["basic-card"],
      data: {
      supportedNetworks: ["visa", "mastercard"],
      },
    },
  ];`}</code>
          </pre>
          <p>
            <DotIcon />
            Set Payment details
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const paymentDetails = {
  total: {
    label: "Total Amount",
    amount: {
      currency: "KSH",
      value: total.toString(),
    },
  },
  displayItems: [
    {
      label: "Discount",
      amount: {
        currency: "KSH",
        value: discount.toString(),
      },
    },
    {
      label: "Taxes, 14% V.A.T",
      amount: {
        currency: "KSH",
        value: tax.toString(),
      },
    },
    ],
  };`}</code>
          </pre>
          <p>
            <DotIcon />
            Requesting additional info
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const options = {
    requestPayerName: true,
    requestPayerEmail: true,
    // requestPayerPhone: true,
    // requestShipping: true,
    // shippingType: "shipping", // or "delivery" or "pickup"
  };`}</code>
          </pre>
          <p>
            <DotIcon />
            Create paydata object
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const paydata = {
    paymentMethods,
    paymentDetails,
    options,
  };`}</code>
          </pre>
          <p>
            <DotIcon />
            Create <b>validatePayment</b> callback function (Do something with
            the Payment Response)
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const validatePayment = async(paymentResponse) => {
  try {
    // Check if payment was successful based on your payment gateway...
    const condition = await yourSuccessHandler(paymentResponse);
  // Please note that complete status can only be "success" or "fail"...
    if (condition) {
      //...
      // Return sucesss...
      await paymentResponse.complete("success");
    } else {
      //...
      // Return fail...
      await paymentResponse.complete("fail");
    }
  };
  } catch(error) {
    throw error;
  }`}</code>
          </pre>
          <p>
            <DotIcon />
            Call <b>Payment</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Pay...
  pwa.Payment(paydata, validatePayment);`}</code>
          </pre>
          <p>
            <DotIcon />
            You can also Destructure the payment response as shown below;
          </p>
          <pre id="copy-code">
            <code className="language-javascript">
              {`//  Destructure response...
  const { details, shippingAddress, shippingOption } = paymentResponse;
  // Destructure to get card details...
      const { cardNumber, cardSecurityCode, cardholderName, expiryMonth, expiryYear } = details;   
  // Destructure to get billing address...
      const {
        addressLine,
        city,
        country,
        dependentLocality,
        organization,
        phone,
        postalCode,
        recipient,
        region,
        sortingCode,
        } = details.billingAddress;`}
            </code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default WebPayment;
