import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const WakeLock = () => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/screen-wakelock",
                title: "Wake Lock API",
                text: "Prevent the device's screen from turning off so that the user can see the information that's displayed on screen",
              }}
            />
          </div>
          <h3>​Keep device's screen awake</h3>
          <p className="quote">
            Prevent the device's screen from turning off so that the user can
            see the information that's displayed on screen.
          </p>
          <p>
            <DotIcon />
            Call the <b>WakeLock</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  pwa.WakeLock();`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default WakeLock;
