import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Badging = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Badging API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/badging",
                title: "Badging API",
                text: "Notify the user of new activity that might require their attention.",
              }}
            />
          </div>
          <h3>​Add badging to your web app</h3>
          <p className="quote">
            <b>Badging</b> makes it easy to notify the user of new activity that
            might require their attention, or indicate a small amount of
            information, such as an unread count for installed PWAs.
          </p>
          <p>
            <DotIcon />
            Set the badge - returns an object, which is either a success or an
            error type
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Set the badge
  const unreadCount = 24;
  pwa.setBadge(unreadCount);`}</code>
          </pre>
          <p>
            <DotIcon />
            Clear the badge
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Clear the badge
  pwa.clearBadge();`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Badging;
