import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const CopyImage = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Copy Image API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/copy-image",
                title: "Copy Image API",
                text: "Read and write image data to the clipboard, without blocking the main thread",
              }}
            />
          </div>
          <h3>​Copy png images to clipboard</h3>
          <p className="quote">
            Read and write image data to the clipboard, without blocking the
            main thread.
          </p>
          <p>
            <DotIcon />
            Call the <b>copyImage</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Copy image         
  pwa.copyImage(imgURL);`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default CopyImage;
