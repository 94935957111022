import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const PickTextFiles = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Pick Text Files API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/pick-text-files",
                title: "Pick Text Files API",
                text: "Pick and read text files",
              }}
            />
          </div>
          <h3>​Pick and read text files</h3>
          <p className="quote">
            Built on top of <b>The File System Access API</b> which allows web
            apps to read or save changes directly to files and folders on the
            user's device.
          </p>
          <p>
            <DotIcon />
            Call the <b>pickTextFile</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Do something with the contents...
  const contents = await pwa.pickTextFile();`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default PickTextFiles;
