import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const CopyText = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Read Text API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Oct 09 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/read-text",
                title: "Read Text API",
                text: "Read text copied to the clipoard",
              }}
            />
          </div>
          <h3>​Read text to clipboard</h3>
          <p className="quote">Read text copied, from the clipoard</p>
          <p>
            <DotIcon />
            Call the <b>readText</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Read Text      
  const res = await pwa.readText();
  const text = res.ok ? res.text : '';`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default CopyText;
