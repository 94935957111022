import { FC } from "react";
import { NavLink } from "react-router-dom";

interface NavItemProps {
  item: {
    title: string;
    path: string;
  };
}

const NavItem: FC<NavItemProps> = ({ item }) => {
  const { title, path } = item;
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive ? "sidebar-nav-item active" : "sidebar-nav-item"
      }>
      {" "}
      {title}
    </NavLink>
  );
};

export default NavItem;
