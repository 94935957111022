import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Notifications = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Notifications API";
  }, []);

  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Mar 31 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/notifications",
                title: "Notifications API",
                text: "Show notifications in your web app",
              }}
            />
          </div>
          <h3>​Show notifications</h3>
          <p className="quote">
            Show notifications in your web app. When your PWA is installed,
            users can be able to see notifications count on your app icon.
          </p>
          <p>
            <DotIcon />
            Add notification data
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const data = {
  title: "Hello Notification!",
  options: {
      body: "Progressive Web App Hello Notification!",
      icon: "../images/icons/icon-192x192.png",
      tag: "pwa",
    },
  };
`}</code>
          </pre>
          <p>
            <DotIcon /> Call the <b>Notification</b> method on pwa, pass in{" "}
            <b>data</b> object
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Call the notification method...
  pwa.Notification(data);`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Notifications;
