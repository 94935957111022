import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const WebShare = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Web Share API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: July 27 2024</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/web-share",
                title: "Web Share API",
                text: "Share links, text, and files to other apps installed on the device. Use the Web Share API to integrate with the OS sharing UI",
              }}
            />
          </div>
          <h3>​Share stuff from a web app</h3>
          <p className="quote">
            Share links, text and files to other apps installed on the device
            using the same system-provided share capabilities as
            platform-specific native apps
          </p>
          <p style={{ fontWeight: 600 }}>
            a) Share links and text to other apps
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const data = {
    // Title of what to share
    title: "Some title..",
    // Text to share
    text: "Some text...",
    // Url to share...
    url: "https://pwafire.org",
  };`}</code>
          </pre>

          <p style={{ fontWeight: 600 }}>b) Share files to other apps</p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const data = {
    // Title of what to share
    title: "Some title..",
    // Text to share
    text: "Some text...",
    // List of files to share...
    files: file_list
  };`}</code>
          </pre>

          <p>
            <DotIcon />
            Call the <b>Share</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  pwa.Share(data);`}</code>
          </pre>

          <p className="quote">
            Web apps can also be <b>share targets</b>, meaning they{" "}
            <b>can receive </b>
            data, links, text, and files from platform-specific or web apps.
            Follow the web.dev guide on{" "}
            <a
              href="https://developer.chrome.com/docs/capabilities/web-apis/web-share-target"
              target="_blank"
              rel="noorefer"
            >
              how to use
            </a>{" "}
            the <b>Web Share Target API</b>.
          </p>
        </section>
      </section>
    </article>
  );
};

export default WebShare;
