import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const ContactsPicker = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Contacts Picker API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/contacts-picker",
                title: "Contacts Picker API",
                text: "Pick and share contacts on the web",
              }}
            />
          </div>
          <h3>Pick and share contacts on the web</h3>
          <p className="quote">
            Access contacts from the mobile device's native contacts manager.{" "}
            <b>Chrome 80 or higher</b> running on Android M or later required.
          </p>
          <p>
            <DotIcon />
            Define the <b>"properties"</b> and <b>"select type"</b> option you
            need
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const props = ["name", "email", "tel"];
  const options = { multiple: true };`}</code>
          </pre>
          <p>
            <DotIcon />
            Call the <b>Contacts</b> method on pwa, the promise resolves with an
            object
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Do something with the promise value...
  const res = await pwa.Contacts(props, options);
  // Do something with contacts...
  const contacts = res.ok ? res.contacts : null;`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default ContactsPicker;
