import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const WebPIP = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Web PIP API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: May 28 2024</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/web-picture-in-picture",
                title: "Web PIP API",
                text: "The Document in Picture-in-Picture API allows websites to create a more functional and user-friendly experience for video players, video conferencing, and productivity tools",
              }}
            />
          </div>
          <h3>​Picture-in-Picture (PIP) for the web</h3>
          <p className="quote">
            This API allows web(sites/apps) to create a more functional and
            user-friendly experience for video players, video conferencing, and
            productivity tools.
          </p>
          <p style={{ fontWeight: 600 }}>
            a) Enable Picture-in-Picture (PIP) for any element e.g video player
          </p>
          <p className="quote">
            Make sure to add id selectors for the player button and container
            with the id <b>"pipButton"</b> and <b>"pipPlayer"</b> respectively
          </p>
          <pre id="copy-code">
            <code className="language-html">{`<div id="container">
  <div id="pipPlayer">
    <video id="video"></video>
  </div>
</div>
<button id="pipButton">Open Picture-in-Picture window</button>`}</code>
          </pre>

          <p style={{ fontWeight: 600 }}>
            b) Call the Picture-in-Picture method
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`const video = document.getElementById('video');
await pwa.webPIP((res) => {
 if (res && res.window) res.window.document?.body.append(video)});`}</code>
          </pre>

          <p>
            <DotIcon />
            Set the size of the Picture-in-Picture window
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`await pwa.webPIP((res) => {}, { width: 640, height: 360 });`}</code>
          </pre>
          <p>
            <DotIcon />
            Hide the "back to tab" button of the Picture-in-Picture window
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`await pwa.webPIP((res) => {}, { disallowReturnToOpener: true });`}</code>
          </pre>
          <p>
            <DotIcon />
            Handle when the Picture-in-Picture window closes
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`await pwa.webPIP((res) => {
if (res && res.window) {
  res.window.addEventListener("pagehide", (event) => {
    const playerContainer = document.querySelector("#playerContainer");
    const pipPlayer = event.target.querySelector("#player");
    playerContainer.append(pipPlayer);
  });
}});`}</code>
          </pre>
          <p>
            <DotIcon />
            Close the Picture-in-Picture window programmatically by using the
            close() method.
          </p>
          <pre id="copy-code">
            <code className="language-javascript">
              {`await pwa.webPIP((res) => {
    if (res && res.window) res.window.close();
});
`}
            </code>
          </pre>
          <p className="quote">
            Learn more about this API in this detailed documentation by google
            devs{" "}
            <a href="https://developer.chrome.com/docs/web-platform/document-picture-in-picture">
              here
            </a>
          </p>
        </section>
      </section>
    </article>
  );
};

export default WebPIP;
