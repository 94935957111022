import {
  CheckCircleIcon,
  CopyIcon,
  ShareAndroidIcon,
} from "@primer/octicons-react";
import { useEffect, useState } from "react";
import { pwa, check } from "pwafire";
import "./share.css";

const Share = ({
  data = {
    url: "https://docs.pwafire.org",
    title: "Progressive Web App API of APIs",
    text: "A collection of Fugu APIs for Progressive Web Apps",
  },
}: {
  data: {
    title: string;
    url: string;
    text: string;
  };
}) => {
  // State..
  const [sharable, setSharable] = useState(false);
  const [copied, setCopied] = useState(false);
  // Effect to check if share spi is supported...
  useEffect(() => {
    check
      .Share()
      .then((ok) => {
        if (ok) {
          setSharable(true);
        } else {
          setSharable(false);
        }
      })
      .catch((err) => {
        setSharable(false);
      });
  }, []);

  // Handle copy to clipboard...
  const handleCopy = async () => {
    try {
      const res = await pwa.copyText(data.url);
      if (res.ok) {
        setCopied(true);
        // Reset after 2 seconds...
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } else {
        setCopied(false);
      }
    } catch (error) {
      setCopied(false);
    }
  };

  // Handle share...
  const handleSHare = async () => {
    try {
      await pwa.Share(data);
    } catch (error) {
      // Do nothing...
    }
  };
  // Render...
  return (
    <span
      onClick={() => {
        if (sharable) {
          handleSHare();
        } else {
          handleCopy();
        }
      }}
      className="app-share-icon">
      <span>{sharable ? "Share" : copied ? "Copied" : "Copy"}</span>
      {sharable ? (
        <ShareAndroidIcon />
      ) : copied ? (
        <CheckCircleIcon />
      ) : (
        <CopyIcon />
      )}
    </span>
  );
};

export default Share;
