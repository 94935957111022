import { ThreeBarsIcon, XIcon } from "@primer/octicons-react";
import { ReactChildren, useContext } from "react";
import styled from "styled-components";
import { MainContext } from "../../contexts";

export const FloatStyledButton = styled.button`
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 12px;
  border-radius: 10px;
  font-size: 10px 12px;
  color: #07a;
  border: none;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  &:hover {
    transform: scale(1.1);
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &.active {
    transform: scale(1.1);
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const FloatButton = ({
  children,
  ...props
}: {
  children?: ReactChildren | string;
  props?: any;
}) => {
  const { showMenu, setShowMenu } = useContext(MainContext);

  return (
    <FloatStyledButton {...props} onClick={() => setShowMenu(!showMenu)}>
      {children ? children : <>{showMenu ? <XIcon /> : <ThreeBarsIcon />}</>}
    </FloatStyledButton>
  );
};
