import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Fullscreen = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Fullscreen API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/fullscreen",
                title: "Fullscreen API",
                text: "Open your web app in fullscreen",
              }}
            />
          </div>
          <h3>​Open your web app in fullscreen</h3>
          <p className="quote">
            This API can be well used by listening on a click event on a given
            button element
          </p>
          <p>
            <DotIcon />
            Call the <b>Fullscreen</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  pwa.Fullscreen();`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Fullscreen;
