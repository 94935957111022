import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const AccessFonts = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Access Local Fonts API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Oct 18 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/access-local-fonts",
                title: "Badging API",
                text: "Allows you to access the user's locally installed fonts and obtain low-level details about them.",
              }}
            />
          </div>
          <h3>Access the user's locally installed fonts</h3>
          <p className="quote">
            <b>Fonts</b> allows you to access the user's locally installed fonts
            and obtain low-level details about them.
          </p>
          <p>
            <DotIcon />
            Call the <b>accessFonts</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const res = await pwa.accessFonts();
  if (res.ok) {
    // Do something.
    const fonts = res.fonts;
  } else {
    // Do something.
  }`}</code>
          </pre>
          <p>
            <DotIcon />
            Accessing <b>SFNT</b> data
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const res = await pwa.accessFonts({
    sfnt: true,
  });
  if (res.ok) {
    // Do something with the sfnt data.
    const sfntFormats = res.sfnt;
  } else {
    // Do something.
  }`}</code>
          </pre>
          <p>
            <DotIcon />
            Get subset of fonts
          </p>
          <p>
            You can also filter them based on the <b>PostScript</b> names by
            adding a <i>postscriptNames</i> parameter, an array of strings.
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  const res = await pwa.accessFonts({
    postscriptNames: ["Verdana", "Verdana-Bold", "Verdana-Italic"],
  });
  if (res.ok) {
    // Do something.
    const fonts = res.fonts;
  } else {
    // Do something.
  }`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default AccessFonts;
