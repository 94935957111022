import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Badging = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Web OTP API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Dec 06 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/web-otp",
                title: "Web OTP API",
                text: "Web OTP API allows you to receive one-time passwords (OTPs) from the SMS message and automatically fill them into the form.",
              }}
            />
          </div>
          <h3>Add Web OTP to your Web App</h3>
          <p className="quote">
            Receive one-time passwords (OTPs) from the SMS message and
            automatically fill them into the form.
          </p>
          <p>
            <DotIcon />
            Please <b>note</b> the following:
          </p>
          <ul>
            <li>
              The message begins with (optional) human-readable text that
              contains a four to ten character alphanumeric string with at{" "}
              <br /> least one number leaving the last line for the URL and the
              OTP.
            </li>
            <li>
              The domain part of the URL of the website that invoked the API
              must be preceded by <b>@</b>.
            </li>
            <li>
              The URL must contain a pound sign ('#') followed by the OTP.
            </li>
          </ul>
          <pre id="copy-code">
            <code className="language-javascript">{`Your OTP is: 123456.
@web-otp.glitch.me #12345`}</code>
          </pre>
          <p>
            <DotIcon />
            Call the <b>WebOTP</b> API on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Web OTP
  await pwa.webOTP((res) => {
  // Do something with the result.
  if (res.ok) {
    // Do something.
    const code = res.code;
  } else {
    // Do something.
  }
});`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default Badging;
