import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const ReadFiles = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Read Files API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Oct 09 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/read-files",
                title: "Read Files API",
                text: "Read files copied to the clipoard for example images",
              }}
            />
          </div>
          <h3>​Read files from the clipboard</h3>
          <p className="quote">
            Read files copied to the clipoard for example images
          </p>
          <p>
            <DotIcon />
            Call the <b>readFiles</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Read files         
  const res = await pwa.readFiles();
  const files = res.ok ? res.files : [];`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default ReadFiles;
