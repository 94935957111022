import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./app.css";
import "./assets/styles/docs.css";
import "./assets/styles/prism.css";
import { FloatButton } from "./components/button";
import { MainProvider } from "./contexts";
import "./index.css";
import {
  AccessFonts,
  Badging,
  Barcode,
  Connectivity,
  ContactsPicker,
  ContentIndexing,
  CopyImage,
  CopyText,
  DisplayMode,
  Docs,
  Fullscreen,
  IdleDetection,
  Install,
  NotFound,
  Notifications,
  PickFile,
  PickTextFiles,
  ReadIFiles,
  ReadText,
  Visibility,
  WakeLock,
  WebOTP,
  WebPIP,
  WebPayment,
  WebShare,
} from "./pages";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MainProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/get-started" replace />} />
        <Route path="/get-started" element={<Docs />} />
        <Route path="/badging" element={<Badging />} />
        <Route path="/barcode" element={<Barcode />} />
        <Route path="/copy-text" element={<CopyText />} />
        <Route path="/copy-image" element={<CopyImage />} />
        <Route path="/contacts-picker" element={<ContactsPicker />} />
        <Route path="/content-indexing" element={<ContentIndexing />} />
        <Route path="/connectivity" element={<Connectivity />} />
        <Route path="/fullscreen" element={<Fullscreen />} />
        <Route path="/idle-detection" element={<IdleDetection />} />
        <Route path="/custom-install-prompt" element={<Install />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/pick-file" element={<PickFile />} />
        <Route path="/pick-text-files" element={<PickTextFiles />} />
        <Route path="/visibility" element={<Visibility />} />
        <Route path="/screen-wakelock" element={<WakeLock />} />
        <Route path="/web-payment" element={<WebPayment />} />
        <Route path="/web-share" element={<WebShare />} />
        <Route path="/display-mode" element={<DisplayMode />} />
        <Route path="/read-text" element={<ReadText />} />
        <Route path="/read-files" element={<ReadIFiles />} />
        <Route path="/access-local-fonts" element={<AccessFonts />} />
        <Route path="/web-otp" element={<WebOTP />} />
        <Route path="/web-picture-in-picture" element={<WebPIP />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <FloatButton />
    </MainProvider>
  </BrowserRouter>
);
