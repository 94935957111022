import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const CopyText = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Copy Text API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Aug 05 2021</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/copy-text",
                title: "Copy Text API",
                text: "Read and write text data to the clipboard, without blocking the main thread",
              }}
            />
          </div>
          <h3>​Copy text to clipboard</h3>
          <p className="quote">
            Read and write text data to the clipboard, without blocking the main
            thread.
          </p>
          <p>
            <DotIcon />
            Call the <b>copyText</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  // Copy text
  const text = "Text to copy"          
  pwa.copyText(text);`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default CopyText;
