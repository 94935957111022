import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const Badging = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Badging API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Jul 17 2022</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/barcode",
                title: "Barcode Detection API",
                text: "Unlock interesting use cases like online payments or web navigation, or use barcodes for establishing social connections on messenger applications.",
              }}
            />
          </div>
          <h3>Scan Barcodes such as QR Codes from your web app</h3>
          <p className="quote">
            Unlock interesting use cases like online payments or web navigation,
            or use <b>barcodes</b> for establishing social connections on
            messenger applications.
          </p>
          <p>
            <DotIcon />
            Setting up
          </p>
          <p className="quote">
            Receives 1 argument, an object with two properties, <b>image</b> and{" "}
            <b>format</b>. The image should be of type;{" "}
            <i>
              Blob or HTMLCanvasElement or HTMLImageElement or HTMLVideoElement
              or ImageBitmap.
            </i>
          </p>
          <p>
            <DotIcon />
            Allowed barcode format values
          </p>

          <pre>
            <code className="language-bash">
              {`  "aztec", "code_128", "code_39", "code_93", "data_matrix", "ean_13", "ean_8", "itf", "pdf417", "qr_code", "upc_e"`}
            </code>
          </pre>
          <p>
            <DotIcon />
            Call the <b>barcodeDetector</b> method on pwa
          </p>
          <pre>
            <code className="language-javascript">{`  // Call the barcodeDetector method on pwa.
  const res = await pwa.barcodeDetector({ image: image, format: "qr_code" });
  // Do something with the result.
  if (res.ok) {
    // Do something.
    const barcodes = res.barcodes;
  } else {
    // Do something.
  }`}</code>
          </pre>

          <p className="quote">
            Learn more about this api{" "}
            <a
              href="https://web.dev/shape-detection/"
              target="_blank"
              rel="noopener noreferrer">
              {" "}
              on this doc
            </a>
            . See it in action{" "}
            <a
              href="https://pwafire-barcode.glitch.me"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>{" "}
            and try it out yourself. Source code{" "}
            <a
              href="https://glitch.com/edit/#!/pwafire-barcode"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        </section>
      </section>
    </article>
  );
};

export default Badging;
