import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface MainContextData {
  isDark: boolean;
  setIsDark: (value: boolean) => void;
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
}

export const MainContext = createContext({
  isDark: false,
  setIsDark: (value: boolean) => {},
  showMenu: true,
  setShowMenu: (value: boolean) => {},
} as MainContextData);

export const MainProvider = ({ children }: any) => {
  const location = useLocation();

  const [isDark, setIsDark] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setShowMenu(window.innerWidth <= 768 ? false : true);
  }, []);

  useEffect(() => {
    if (location) {
      if (window.innerWidth <= 768 && currentPath !== location.pathname) {
        setShowMenu(false);
      } else {
        setCurrentPath(location.pathname);
      }
    }
  }, [currentPath, location]);
  return (
    <MainContext.Provider
      value={{
        isDark,
        setIsDark,
        showMenu,
        setShowMenu,
      }}>
      {children}
    </MainContext.Provider>
  );
};
